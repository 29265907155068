<template>
  <div>
    <button class="btn-action" style="color: var(--dark); padding: 3.5px 5px; font-size: 12.8px;" @click="showModal = true, initialChanges()"><i class="fas fa-user-edit"></i></button>

    <div class="modal fade" :class="{show: showModal}" :style="{display: showModal ? 'block' : 'none'}">
      <div class="modal-dialog modal-dialog-scrollable" role="dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Editar Cliente</h4>
            <button type="button" class="close" @click="closeModal()" data-bs-dismiss="modal" aria-label="Close">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <form @submit.prevent="EditCustomer()">
            <div class="modal-body">
              <h5 class="mb-2">Informações da Empresa</h5>
              <div class="form-group">
              <p class="mb-0 font-weight-bold">CNPJ</p>
                <div class="input-group">
                  <input
                    type="cnpj"
                    v-model="user.cnpj"
                    class="form-control"
                    placeholder="CNPJ da Empresa"
                    v-mask="'##.###.###/####-##'"
                    required
                  >
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroupPrepend2" @click="autoInfoRequest()">
                      <template v-if="infoLoading">
                        <i class="fas fa-circle-notch fa-spin"></i>
                      </template>
                      <template v-else>
                        <i class="fa fa-search"></i>
                      </template>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Razão Social</p>
                <input
                  type="name"
                  v-model="user.name"
                  class="form-control"
                  placeholder="Razão Social"
                  required
                  style="text-transform:uppercase"
                >
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Nome Fantasia</p>
                <input
                  type="name"
                  v-model="user.fantasia"
                  class="form-control"
                  placeholder="Nome Fantasia"
                  required
                >
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Celular</p>
                <input
                  type="phone"
                  v-model="user.phone"
                  class="form-control"
                  placeholder="Celular do Responsável"
                  v-mask="user.phone.length >= 15 ? '(##) #####-####' : '(##) ####-####'"
                  minlength="14"
                  required
                >
              </div>
              <div class="container-flex">
                <div class="row">
                  <div class="form-group col-4">
                    <p class="mb-0 font-weight-bold">Estado</p>
                    <input
                      type="uf"
                      v-model="user.uf"
                      class="form-control"
                      placeholder="UF"
                      v-mask="'AA'"
                      style="text-transform:uppercase"
                      required
                    >
                  </div>
                  <div class="form-group col-8 pl-0">
                    <p class="mb-0 font-weight-bold">Município</p>
                    <input
                      autocomplete="false"
                      type=""
                      v-model="user.city"
                      class="form-control"
                      placeholder="Município"
                      required
                    >
                  </div>
                </div>
              </div>
              <div class="container-flex">
                <div class="row">
                  <div class="form-group col-10">
                    <p class="mb-0 font-weight-bold">Rua</p>
                    <input
                      type="uf"
                      v-model="user.street"
                      class="form-control"
                      placeholder="Rua"
                      required
                    >
                  </div>
                  <div class="form-group col-2 pl-0">
                    <p class="mb-0 font-weight-bold">N°</p>
                    <input
                      autocomplete="false"
                      type=""
                      v-model="user.streetNumber"
                      class="form-control"
                      placeholder="N°"
                      required
                    >
                  </div>
                </div>
              </div>

              <div class="container-flex">
                <div class="row">
                  <div class="form-group col-8">
                    <p class="mb-0 font-weight-bold">Bairro</p>
                    <input
                      autocomplete="false"
                      type=""
                      v-model="user.neighborhood"
                      class="form-control"
                      placeholder="Bairro"
                      required
                    >
                  </div>
                  <div class="form-group col-4 pl-0">
                    <p class="mb-0 font-weight-bold">CEP</p>
                    <input
                      autocomplete="false"
                      type=""
                      v-model="user.zipcode"
                      class="form-control"
                      placeholder="CEP"
                      v-mask="'#####-###'"
                      required
                    >
                  </div>
                </div>
              </div>

              <h5 class="mt-4 mb-3">Créditos do Cliente</h5>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Emissões Disponíveis</p>
                <input
                  type=""
                  v-model="user.emissions"
                  class="form-control"
                  placeholder="Emissões Disponíveis"
                  required
                >
              </div>

              <label class="mt-3 custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" :checked="user.free" @click="user.free = !user.free">
                <span class="custom-control-label">Plano Totalmente Liberado (Emergencia)</span>
              </label>

              <h5 class="mt-4 mb-3">Login do Cliente</h5>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">E-mail de Acesso</p>
                <input
                  type="email"
                  v-model="user.email"
                  class="form-control"
                  placeholder="E-mail de Login"
                  required
                  disabled
                >
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Senha Provisória</p>
                <input
                  type="password"
                  v-model="user.password"
                  class="form-control"
                  placeholder="Defina sua Senha"
                  minlength="8"
                  required
                  disabled
                >
              </div>
            </div>
            <div class="modal-footer">
              <!-- <button type="button" class="btn btn-secondary" @click="closeModal()" data-bs-dismiss="modal">Close</button> -->
              <!-- <p class="status" :style="[status ? { 'background-color' : '#fff1c6' } : { 'background-color' : '#fff' }]">{{status}}</p> -->
              <button class="btn btn-primary" :disabled="loading">
                <template v-if="loading" style="transition: all .3s !important">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </template>
                <template v-else>
                  <i class="fas fa-pen"></i>Editar Cliente
                </template>
              </button>

            </div>
          </form>
        </div>
      </div>
      <div v-if="status.info" class="alert text-center" :class="status.color" role="alert">
        {{status.info}}
      </div>
      <div class="modal-backdrop fade" :class="{show: showModal}" @click="closeModal()" :style="{display: showModal ? 'block' : 'none'}"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['userData'],
  data: () => ({
    showModal: false,
    loading: false,
    hasCpf: false,
    infoLoading: false,
    status: {
      info: '',
      color: ''
    },
    user: {
      actived: '',
      email: '',
      password: '',
      cnpj: '',
      currentSubscriptionId: '',
      currentPlanId: '',
      lastUploadEmissions: '',
      lastBoletoUrl: '',
      totalGuides: '',
      emissions: 0,
      name: '',
      phone: '',
      uf: '',
      city: '',
      zipcode: '',
      street: '',
      streetNumber: '',
      neighborhood: '',
      fantasia: '',
      emailToSend: '',
      passwordToSend: '',
      uId: '',
      pagarmeId: '',
      createdAt: '',
      affiliateId: '',
      free: true
    }
  }),

  methods: {
    initialChanges () {
      this.user = this.userData
      if (!this.user.free) {
        this.user.free = false
      }
    },
    closeModal () {
      this.showModal = false
      this.$root.$emit('Refresh::users')
    },
    async EditCustomer () {
      if (this.user.cnpj.length < 18) {
        this.status = {
          info: 'CNPJ Inválido',
          color: 'alert-warning'
        }
        setTimeout(() => {
          this.status = {
            info: '',
            color: ''
          }
        }, 3000)
        return null
      }
      if (this.user.cpf && this.user.cpf.length < 11) {
        this.status = {
          info: 'CPF Inválido',
          color: 'alert-warning'
        }
        setTimeout(() => {
          this.status = {
            info: '',
            color: ''
          }
        }, 3000)
        return null
      }

      this.loading = true
      this.users = []

      // if (this.oldEmail !== this.user.email || this.oldPassword !== this.user.password) {
      //   await this.$firebase.auth()
      //     .signInWithEmailAndPassword(this.oldEmail, this.oldPassword)
      //     .then(userCredential => {
      //       userCredential.user.updateEmail(this.user.email)
      //       userCredential.user.updatePassword(this.user.password)
      //     })
      // }
      this.$firebase.database().ref('users').child(this.user.uId).update(this.user, err => {
        if (err) {
          console.log(err)
        } else {
          this.status = {
            info: 'Cliente Editado',
            color: 'alert-success'
          }
        }
      })

      this.$root.$emit('Refresh::users')

      setTimeout(() => {
        this.loading = false
      }, 2000)

      setTimeout(() => {
        this.status = {
          info: '',
          color: ''
        }
      }, 3000)

      setTimeout(() => {
        this.showModal = false
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content{
  .modal-body{
    padding-top: 0;
    height: 70vh;
  }

  .modal-header, .modal-footer{
    border: none !important;
  }

  .custom-checkbox{
    margin: 10px 0 0 2px;
  }

  .custom-control-label::before{
    height: 1.2rem !important;
    width: 1.2rem !important;
    top: .15rem;
    left: -1.60rem;
    box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.15) !important;
    border-color: white !important;
    border-radius: 5px;
  }
  .custom-control-label::after{
    border-color: var(--featured) !important;
  }
  .custom-control-input:checked~.custom-control-label::before {
    box-shadow: none !important;
    color: white !important;
    background-color: var(--featured) !important;
    border-color: var(--featured) !important;
    &:focus {
        border: none !important;
    }
  }
  h4, h5{
    font-weight: 700;
  }
  .form-group{
    margin: 7px 0;
  }
  .btn-remove{
    color: var(--red-light);
    padding: 0;
    border: none;
    background-color: white;
    margin-left: 3px;
    i {
      margin-right: 5px;
    }
  }
}
.input-group-text{
  position: absolute;
  background-color: var(--featured) !important;
  color: white;
  right: 2px;
  bottom: 3px;
  top: 3px;
  border: none;
  cursor: pointer;
  border-radius: 10px !important;
  z-index: 999;
}
</style>
