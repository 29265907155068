<template>
  <div>
    <button class="btn btn-sm btn-primary" @click="showModal = true">
      <i class="fas fa-plus"></i>Adicionar Afiliado
    </button>

    <div class="modal fade" :class="{show: showModal}" :style="{display: showModal ? 'block' : 'none'}">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Adicionar Afiliado</h4>
            <button type="button" class="close" @click="closeModal()" data-bs-dismiss="modal" aria-label="Close">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <form @submit.prevent="createAffiliate()">
            <div class="modal-body">
              <h5 class="mb-3">Informações Pessoais</h5>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">CNPJ</p>
                <input
                  type="cnpj"
                  class="form-control"
                  placeholder="CNPJ"
                  v-model="affiliate.cnpj"
                  v-mask="'##.###.###/####-##'"
                >
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Nome</p>
                <input
                  type="name"
                  class="form-control"
                  placeholder="Nome"
                  v-model="affiliate.name"
                  required
                  style="text-transform:uppercase"
                >
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">CPF</p>
                <input
                  type="cnpj"
                  class="form-control"
                  placeholder="CPF"
                  v-model="affiliate.cpf"
                  v-mask="'###.###.###-##'"
                  required
                >
              </div>
              <div class="row">
                <div class="form-group col-4">
                  <p class="mb-0 font-weight-bold">Estado</p>
                  <input
                    type="uf"
                    v-model="affiliate.uf"
                    class="form-control"
                    placeholder="UF"
                    v-mask="'AA'"
                    style="text-transform:uppercase"
                    required
                  >
                </div>
                <div class="form-group col-8 pl-0">
                  <p class="mb-0 font-weight-bold">Cidade</p>
                  <input
                    autocomplete="false"
                    type=""
                    v-model="affiliate.city"
                    class="form-control"
                    placeholder="Município"
                    required
                  >
                </div>
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Celular</p>
                <input
                  type="phone"
                  v-model="affiliate.phone"
                  class="form-control"
                  placeholder="Celular do Responsável"
                  v-mask="affiliate.phone.length >= 15 ? '(##) #####-####' : '(##) ####-####'"
                  minlength="14"
                  required
                >
              </div>
              <div class="row">
                <div class="col-8">
                  <div class="form-group">
                    <p class="mb-0 font-weight-bold">Indicação</p>
                    <input
                      type="name"
                      class="form-control"
                      placeholder="Indicação"
                      v-model="affiliate.indicated"
                      required
                    >
                  </div>
                </div>
                <div class="col-4 pl-0">
                  <div class="form-group">
                    <p class="mb-0 font-weight-bold">Comissão</p>
                    <select class="form-control" v-model="affiliate.percentage" required>
                      <option value="5">5%</option>
                      <option value="10">10%</option>
                      <option value="15">15%</option>
                      <option value="20">20%</option>
                      <option value="25">25%</option>
                      <option value="30">30%</option>
                    </select>
                  </div>
                </div>
              </div>
              <h5 class="mt-3 mb-3">Login do Afiliado</h5>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">E-mail de acesso</p>
                <input
                  type="email"
                  v-model="affiliate.email"
                  class="form-control"
                  placeholder="E-mail de Login"
                  autocomplete="off"
                  onfocus="this.removeAttribute('readonly');" readonly
                  required
                >
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Senha</p>
                <input
                  type="password"
                  v-model="affiliate.password"
                  class="form-control"
                  placeholder="Defina sua Senha"
                  minlength="8"
                  autocomplete="off"
                  onfocus="this.removeAttribute('readonly');" readonly
                  required
                >
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-primary" :disabled="loading">
                <template v-if="loading" style="transition: all .3s !important">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </template>
                <template v-else>
                  <i class="fas fa-plus"></i>Adicionar Afiliado
                </template>
              </button>

            </div>
          </form>
        </div>
      </div>
      <div v-if="status.info" class="alert text-center" :class="status.color" role="alert">
        {{status.info}}
      </div>
      <div class="modal-backdrop fade" :class="{show: showModal}" @click="closeModal()" :style="{display: showModal ? 'block' : 'none'}"></div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    showModal: false,
    loading: false,
    status: {
      info: '',
      color: ''
    },
    affiliate: {
      affiliateId: '',
      actived: true,
      cnpj: '',
      cpf: '',
      name: '',
      phone: '',
      uf: '',
      city: '',
      percentage: '',
      indicated: '',
      email: '',
      password: ''
    }
  }),

  methods: {
    closeModal () {
      this.$root.$emit('Refresh::affiliates')
      this.showModal = false
    },
    async createAffiliate () {
      // if (this.affiliate.cnpj.length < 16) {
      //   this.status = {
      //     info: 'CNPJ Inválido',
      //     color: 'alert-warning'
      //   }
      //   setTimeout(() => {
      //     this.status = {
      //       info: '',
      //       color: ''
      //     }
      //   }, 3000)
      //   return null
      // }
      // this.loading = true
      // this.affiliates = []

      // this.$firebase.database().ref(`affiliates/${window.uid}`).on('value', snapshot => {
      //   const values = snapshot.val()
      //   if (values) {
      //     this.affiliates = Object.keys(values).map(i => values[i])
      //   }
      // })

      // if (this.affiliates.length > 0) {
      //   for (let i = 0; i < this.affiliates.length; i++) {
      //     if (this.affiliates[i].cnpj === this.affiliates.cnpj) {
      //       this.status = {
      //         info: 'CNPJ Já Cadastrado',
      //         color: 'alert-danger'
      //       }
      //       setTimeout(() => {
      //         this.loading = false
      //       }, 2000)

      //       setTimeout(() => {
      //         this.status = {
      //           info: '',
      //           color: ''
      //         }
      //       }, 3000)
      //       return null
      //     }
      //   }
      // }

      this.affiliate.occupation = 'affiliate'

      try {
        const res = await this.$authWorker.auth().createUserWithEmailAndPassword(this.affiliate.email, this.affiliate.password)
        this.affiliate.affiliateId = res.user.uid
        await this.$authWorker.auth().signOut()
        await this.$firebase.database().ref('affiliates').child(this.affiliate.affiliateId).set(this.affiliate, err => {
          if (err) {
            console.log(err)
          } else {
            this.status = {
              info: 'Afiliado Adicionado',
              color: 'alert-success'
            }
            this.affiliate = {
              uId: '',
              affiliateId: '',
              cnpj: '',
              cpf: '',
              name: '',
              phone: '',
              uf: '',
              city: '',
              percentage: '',
              indicated: '',
              email: '',
              password: ''
            }
          }
        })
      } catch (err) {
        console.log(err)
        if (err.message === 'The email address is already in use by another account.') {
          this.status = {
            info: 'Este e-mail já está sendo usado em outra conta!',
            color: 'alert-danger'
          }
        }

        await this.$firebase.database().ref('affiliateLinks').child(this.affiliate.affiliateId.substring(0, 10)).set({
          access: 0,
          registered: 0,
          registerAccessPage: 0,
          affiliateId: this.affiliate.affiliateId
        })

        setTimeout(() => {
          this.loading = false
        }, 2000)

        setTimeout(() => {
          this.status = {
            info: '',
            color: ''
          }
        }, 3000)
        this.loading = false
        return null
      }

      setTimeout(() => {
        this.loading = false
      }, 2000)

      setTimeout(() => {
        this.status = {
          info: '',
          color: ''
        }
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content{
  width: 580px !important;

  .modal-body{
    padding-top: 0;
    height: 70vh;
  }

  .modal-header, .modal-footer{
    border: none !important;
  }

  .custom-checkbox{
    margin: 10px 0 0 2px;
  }

  .custom-control-label::before{
    height: 1.2rem !important;
    width: 1.2rem !important;
    top: .15rem;
    left: -1.60rem;
    box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.15) !important;
    border-color: white !important;
    border-radius: 5px;
  }
  .custom-control-label::after{
    border-color: var(--featured) !important;
  }
  .custom-control-input:checked~.custom-control-label::before {
    box-shadow: none !important;
    color: white !important;
    background-color: var(--featured) !important;
    border-color: var(--featured) !important;
    &:focus {
        border: none !important;
    }
  }
  h4, h5{
    font-weight: 700;
  }
  .form-group{
    margin: 7px 0;
  }
  .btn-remove{
    color: var(--red-light);
    padding: 0;
    border: none;
    background-color: white;
    margin-left: 3px;
    i {
      margin-right: 5px;
    }
  }
}
</style>
