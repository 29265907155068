<template>
  <div class="container-fluid">
    <div class="row">
      <layout-navigation/>
      <div class="col-lg-12 col-content">
       <div class="content">
          <h5 class="router-text">Afiliados</h5>
          <div class="container-fluid mt-3">
            <div class="row info-container">
              <div>
                <h4>
                  Adicione aqui seus novos afiliados
                </h4>
                <p class="mt-3">
                  Não perca mais tempo com os processos chatos, os nossos robôs<br>fazem isso por você e pela a sua empresa!
                </p>
                <div class="container">
                  <div class="row">
                    <new-affiliate/>
                    <button class="btn btn-secondary btn-sm ml-2" @click="$router.push({ name: 'home' })">
                        Ir Para Home
                    </button>
                  </div>
                </div>
              </div>
              <!--<new-customer style="margin-top: -145px;"/>-->
            </div>
          </div>
          <div class="container-fluid mt-3">
            <div class="row search-container">
              <div class="col-8">
                <input
                  type="name"
                  v-model="searchValue"
                  class="form-control"
                  placeholder="Digite quem deseja buscar..."
                  required
                >
              </div>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">Nome</th>
                <th scope="col">Ações</th>
                <th scope="col">Clientes</th>
                <th scope="col">Assinantes</th>
                <th scope="col">Faturamento</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr
                :key="i"
                v-for="(affiliate, i) in filteredCustomers"
              >
                <td>
                  <template v-if="affiliate.actived">
                    <div class="column">
                      <div
                        class="status status-success text-center"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Ativo">
                      </div>
                      <p class="status-info">Ativo</p>
                    </div>
                  </template>
                  <template v-else>
                    <div class="column">
                      <div
                        class="status status-danger text-center"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Desligado">
                      </div>
                      <p class="status-info">Desligado</p>
                    </div>
                  </template>
                </td>
                <template v-if="affiliate && affiliate.name.length > 13">
                  <td>{{affiliate.name.substring(0, 15)}}...</td>
                </template>
                <template v-else>
                  <td>{{affiliate.name}}</td>
                </template>
                <td>
                  <div class="row" style="padding: 0 12px !important;">
                    <active-affiliate :affiliate="affiliate"/>
                    <delete-affiliate :affiliate="affiliate"/>
                    <edit-affiliate :affiliateData="affiliate"/>
                  </div>
                </td>
                <td v-if="affiliate.affiliateCustomers">{{affiliate.affiliateCustomers}}</td><td v-else>0</td>
                <td v-if="affiliate.affiliateSubscribers">{{affiliate.affiliateSubscribers}}</td><td v-else>0</td>
                <td v-if="affiliate.affiliateBilling">R$ {{affiliate.affiliateBilling}}</td><td v-else>0</td>
                <td style="width: 135px;"><a class="btn btn-sm btn-primary" @click="accessAccount(affiliate.email, affiliate.password)">Acessar</a></td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
              :key="i"
              v-for="(installment, i) in 15"
              >
                <td><div class="loading-sm"></div></td>
                <td><div class="loading-md"></div></td>
                <td><div class="loading-sm"></div></td>
                <td><div class="loading-md"></div></td>
                <td><div class="loading-md"></div></td>
                <td><div class="loading-sm"></div></td>
                <td><div class="loading-sm"></div></td>
                <td><div class="loading-sm"></div></td>
                <td><div class="loading-md"></div></td>
              </tr>
            </tbody>
          </table>
       </div>
      </div>
    </div>
    <div class="modal fade" :class="{show: showTutorial}" :style="{display: showTutorial ? 'block' : 'none'}">
      <div class=" modal-dialog modal-dialog-centered modal-lg" role="dialog" style="transform: translate(0, -40px); z-index: 9999;">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <button type="button" class="close" @click="showTutorial = false; pauseVideo()" data-bs-dismiss="modal" aria-label="Close">
              <i class="fas fa-times" style="color: white;"></i>
            </button>
          </div>
          <div class="row justify-content-center player">
            <youtube player-width="800" player-height="450" :video-id="'HzU5zVX17no'" ref="youtube"></youtube>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade" :class="{show: showTutorial}" @click="showTutorial = false; pauseVideo()" :style="{display: showTutorial ? 'block' : 'none'}"></div>
    </div>
  </div>
</template>

<script>
import LayoutNavigation from '../../components/layout/LayoutNavigation'
import NewAffiliate from './NewAffiliate'
import EditAffiliate from './EditAffiliate'
import DeleteAffiliate from './DeleteAffiliate'
import ActiveAffiliate from './ActiveAffiliate'

export default {
  name: 'Home',
  components: {
    LayoutNavigation,
    NewAffiliate,
    EditAffiliate,
    DeleteAffiliate,
    ActiveAffiliate
  },

  data: () => ({
    affiliates: [],
    users: [],
    subscriptions: [],
    searchValue: '',
    loading: true,
    showTutorial: false
  }),

  async created () {
    this.$root.$on('Refresh::affiliates', this.getAffiliates)
    await this.getSubscriptions()
    await this.getUsers()
    await this.getAffiliates()
    setTimeout(() => {
      this.affiliatesInfo()
      this.loading = false
    }, 2000)
  },

  computed: {
    filteredCustomers () {
      let tempCustomers = this.affiliates

      if (this.searchValue !== '' && this.searchValue) {
        tempCustomers = tempCustomers.filter((item) => {
          return item.cnpj
            .toUpperCase()
            .includes(this.searchValue.toUpperCase()) + item.name
            .toUpperCase()
            .includes(this.searchValue.toUpperCase())
        })
      }

      tempCustomers = tempCustomers.sort((a, b) => {
        if (this.sortBy === 'alphabetically') {
          const fa = a.name.toLowerCase()
          const fb = b.name.toLowerCase()
          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        }
      })

      return tempCustomers
    }
  },

  methods: {
    accessAccount (email, password) {
      window.open(`https://afiliados.emissaosimples.com.br/login/${this.CryptoJS.AES.encrypt(email, 'Secret Passphrase').toString().replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l')}/${this.CryptoJS.AES.encrypt(password, 'Secret Passphrase').toString().replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l')}`, '_blank')
    },
    async getUsers () {
      await this.$firebase.database().ref('users').on('value', snapshot => {
        const values = snapshot.val()
        if (values) {
          this.users = Object.keys(values).map(i => values[i])
        }
      })
    },
    affiliateBilling (affiliateId) {
      let totalBilling = 0
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].affiliateId === affiliateId) {
          for (let z = 0; z < this.subscriptions.length; z++) {
            if (this.subscriptions[z].id === this.users[i].currentSubscriptionId) {
              if (this.subscriptions[z].status === 'paid') {
                totalBilling += this.subscriptions[z].plan.amount
              }
            }
          }
        }
      }
      return (totalBilling / 100).toFixed(2)
    },
    affiliateCommission (affiliateId, percentage) {
      let totalBilling = 0
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].affiliateId === affiliateId) {
          for (let z = 0; z < this.subscriptions.length; z++) {
            if (this.subscriptions[z].id === this.users[i].currentSubscriptionId) {
              if (this.subscriptions[z].status === 'paid') {
                totalBilling += this.subscriptions[z].plan.amount
              }
            }
          }
        }
      }
      return ((totalBilling / 100) * (percentage / 100)).toFixed(2)
    },
    affiliateSubscribers (affiliateId) {
      let totalSubscribers = 0
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].affiliateId === affiliateId) {
          for (let z = 0; z < this.subscriptions.length; z++) {
            if (this.subscriptions[z].id === this.users[i].currentSubscriptionId) {
              if (this.subscriptions[z].status === 'paid') {
                totalSubscribers += 1
              } else if (this.subscriptions[z].status === 'canceled') {
                totalSubscribers += 1
              } else {
                totalSubscribers += 1
              }
            }
          }
        }
      }
      return totalSubscribers
    },
    affiliateCustomers (affiliateId) {
      let totalCustomers = 0
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].affiliateId === affiliateId) {
          totalCustomers += 1
        }
      }
      return totalCustomers
    },
    async getSubscriptions () {
      await this.$firebase.database().ref('apiKeys').child('pagarmeApiKey').once('value', snapshot => {
        this.$pagarme.client.connect({ api_key: snapshot.val() }).then(client => client.subscriptions.all({ count: 1000 })).then(subscription => {
          this.subscriptions = subscription
        })
      })
    },
    async affiliatesInfo () {
      for (let i = 0; i < this.affiliates.length; i++) {
        this.affiliates[i].affiliateSubscribers = this.affiliateSubscribers(this.affiliates[i].affiliateId)
        this.affiliates[i].affiliateCustomers = this.affiliateCustomers(this.affiliates[i].affiliateId)
        this.affiliates[i].affiliateBilling = this.affiliateBilling(this.affiliates[i].affiliateId)
      }
    },
    async getAffiliates () {
      await this.$firebase.database().ref('affiliates').once('value', snapshot => {
        const values = snapshot.val()
        if (values) {
          this.affiliates = Object.keys(values).map(i => values[i])
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.status-info{
    min-width: 85px;
}
.loading-sm{
  width: 85px;
  height: 27px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 8px;
}
.loading-md{
  width: 120px;
  height: 27px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 8px;
}
.loading-lg{
  width: 180px;
  height: 27px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 8px;
}
.loading-btn{
  width: 138px;
  height: 47px;
  background: gray;
  border-radius: 10px;
}
.modal-header{
  border-bottom: none !important;
  button{
    opacity: 1 !important;
    padding-right: 0 !important;
  }
}
.modal-content{
  background-color: transparent !important;
  .row{
    padding: 0 !important;
  }
}
.player {
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;
    height: 450px;
    width: 800px;
    transform: translate(18px, 0);
}
</style>
