<template>
  <div class="container-fluid">
    <div class="row">
      <layout-navigation/>
      <div class="col-lg-12 col-content">
       <div class="content">
          <h5 class="router-text">Leads</h5>
          <div class="container-fluid mt-3">
            <div class="row info-container">
              <div>
                <h4>
                  Organize por aqui suas leads
                </h4>
                <p class="mt-3">
                  O Emissão Simples automatiza até mesmo a busca de leads<br>de novas contabilidades por você.
                </p>
              </div>
              <!--<new-customer style="margin-top: -145px;"/>-->
            </div>
          </div>
          <div class="container-fluid mt-3">
            <div class="row search-container">
              <div class="col-6 pl-0">
                <input
                  type="name"
                  v-model="searchValue"
                  class="form-control"
                  placeholder="Digite quem deseja buscar..."
                  required
                >
              </div>
              <div class="col-3 pl-0">
                <div class="form-group mb-0">
                  <select class="form-control" @change="getCities" v-model="state" required>
                    <option :key="i" v-for="(stateName, i) in stateList">{{stateName}}</option>
                  </select>
                </div>
              </div>
              <div class="col-3 pl-0">
                <div class="form-group mb-0">
                  <select class="form-control" @change="getLeads" v-model="city" required>
                    <option :key="i" v-for="(cityName, i) in cityList">{{cityName}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">Razão Social</th>
                <th scope="col">Ações</th>
                <th scope="col">Contato</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr
                :key="i"
                v-for="(lead, key, i) in filteredLeads"
              >
              <td>
                  <template v-if="lead.loading">
                    <div class="column text-center">
                      <div>
                        <i class="fas fa-circle-notch fa-spin" style="font-size: 18px"></i>
                      </div>
                      <p class="status-info">Processando...</p>
                    </div>
                  </template>
                  <template v-else>
                    <div class="column" @mouseover="hover = i">
                      <div
                        class="status text-center"
                        :class="[lead.status ? lead.status.color : '']"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        :title="lead.status.info">
                      </div>
                      <template v-if="lead.status.info === 'Retornar'">
                        <p class="status-info">{{lead.status.info}}</p>
                        <p class="status-info">{{moment(String(lead.dtReturn)).format('DD/MM/YYYY')}}</p>
                      </template>
                      <template v-else>
                        <p class="status-info">{{lead.status.info}}</p>
                      </template>
                    </div>
                  </template>
                </td>

                <template v-if="lead.name && lead.name.length > 35">
                  <td style="width: 400px !important">{{lead.name.substring(0, 35)}}...</td>
                </template>
                <template v-else>
                  <td style="width: 400px !important">{{lead.name}}</td>
                </template>

                <template v-if="lead.affiliateId === affiliateId">
                  <td>
                    <div class="row" style="padding: 0 12px !important;">
                      <new-user :lead="lead"/>
                      <edit-lead :leadData="lead" :state="state" :city="city"/>
                    </div>
                  </td>
                </template>
                <template v-else>
                  <td><div class="loading-md"></div></td>
                </template>

                <template v-if="lead.affiliateId === affiliateId">
                  <td>{{lead.phone}}</td>
                </template>
                <template v-else>
                  <td><div class="loading-md"></div></td>
                </template>

                <template v-if="lead.affiliateId === affiliateId">
                  <td style="width: 160px !important"><a class="btn btn-sm btn-primary disabled">Obter Lead</a></td>
                </template>
                <template v-else>
                  <td style="width: 160px !important"><a class="btn btn-sm btn-primary" @click="putLeadAfilliate(lead.leadKey)">Obter Lead</a></td>
                </template>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
              :key="i"
              v-for="(installment, i) in 15"
              >
                <td><div class="loading-sm"></div></td>
                <td><div class="loading-md"></div></td>
                <td><div class="loading-sm"></div></td>
                <td><div class="loading-md"></div></td>
                <td><div class="loading-md"></div></td>
                <td><div class="loading-sm"></div></td>
                <td><div class="loading-sm"></div></td>
              </tr>
            </tbody>
          </table>
       </div>
      </div>
    </div>
    <div class="modal fade" :class="{show: showTutorial}" :style="{display: showTutorial ? 'block' : 'none'}">
      <div class=" modal-dialog modal-dialog-centered modal-lg" role="dialog" style="transform: translate(0, -40px); z-index: 9999;">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <button type="button" class="close" @click="showTutorial = false; pauseVideo()" data-bs-dismiss="modal" aria-label="Close">
              <i class="fas fa-times" style="color: white;"></i>
            </button>
          </div>
          <div class="row justify-content-center player">
            <youtube player-width="800" player-height="450" :video-id="'HzU5zVX17no'" ref="youtube"></youtube>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade" :class="{show: showTutorial}" @click="showTutorial = false; pauseVideo()" :style="{display: showTutorial ? 'block' : 'none'}"></div>
    </div>
  </div>
</template>

<script>
import LayoutNavigation from '../../components/layout/LayoutNavigation'
import NewUser from './NewUser'
import EditLead from './EditLead'

export default {
  name: 'Home',
  components: {
    LayoutNavigation,
    NewUser,
    EditLead
  },

  data: () => ({
    leads: [],
    searchValue: '',
    loading: false,
    showTutorial: false,
    plans: [],
    subscriptions: [],
    affiliateId: window.uid,
    state: '',
    city: '',
    stateList: [],
    cityList: []
  }),

  created () {
    this.$root.$on('Refresh::leads', this.getLeads)
    this.getStates()
    this.getLastSearch()
  },

  computed: {
    filteredLeads () {
      let tempCustomers = this.leads

      if (this.searchValue !== '' && this.searchValue) {
        tempCustomers = tempCustomers.filter((item) => {
          return item.name
            .toUpperCase()
            .includes(this.searchValue.toUpperCase())
        })
      }

      tempCustomers = tempCustomers.sort((a, b) => {
        if (this.sortBy === 'alphabetically') {
          const fa = a.name.toLowerCase()
          const fb = b.name.toLowerCase()
          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        }
      })

      tempCustomers = tempCustomers.sort((a, b) => {
        const fa = a.affiliateId.toLowerCase()
        const fb = b.affiliateId.toLowerCase()
        if (fa > fb) {
          return -1
        }
        if (fa < fb) {
          return 1
        }
        return 0
      })

      tempCustomers = tempCustomers.sort((a, b) => {
        const fa = a.status.info
        if (fa === 'Assinante') {
          return -1
        }
        return 0
      })

      tempCustomers = tempCustomers.sort((a, b) => {
        const fa = a.status.info
        if (fa === 'Testando') {
          return -1
        }
        return 0
      })

      tempCustomers = tempCustomers.sort((a, b) => {
        const fa = a.status.info
        if (fa === 'Retornar') {
          return -1
        }
        return 0
      })

      // tempCustomers = tempCustomers.sort((a, b) => {
      //   const fb = b.status.info
      //   if (fb === 'Apresentado') {
      //     return -1
      //   }
      //   return 0
      // })

      tempCustomers = tempCustomers.sort((a, b) => {
        const fb = b.status.info
        if (fb === 'Não Quer') {
          return -1
        }
        return 0
      })

      return tempCustomers
    }
  },

  methods: {
    async getLastSearch () {
      await this.$firebase.database().ref('affiliates').child(window.uid).child('lastLeadSearch').on('value', snapshot => {
        const values = snapshot.val()
        if (values) {
          this.state = values.state
          this.getCities()
          this.city = values.city
          this.getLeads()
        }
      })
    },

    async getLeads () {
      let leadsTemp
      if (this.state && this.city) {
        await this.$firebase.database().ref('leads').child(this.state).child('cities').child(this.city).child('leads').on('value', snapshot => {
          const values = snapshot.val()
          if (values) {
            leadsTemp = Object.keys(values).map(i => values[i])
            this.leads = []
            for (let i = 0; i < leadsTemp.length; i++) {
              if (leadsTemp[i].affiliateId === window.uid || leadsTemp[i].affiliateId === '') {
                this.leads.push(leadsTemp[i])
              }
            }
            this.$firebase.database().ref('affiliates').child(window.uid).update({ lastLeadSearch: { state: this.state, city: this.city } })
          }
        })
      }
    },

    async getStates () {
      let statesTemp
      await this.$firebase.database().ref('leads').on('value', snapshot => {
        const values = snapshot.val()
        if (values) {
          statesTemp = Object.keys(values).map(i => values[i])
          this.stateList = []
          for (let i = 0; i < statesTemp.length; i++) {
            this.stateList.push(statesTemp[i].stateName)
          }
        }
      })
    },

    async getCities () {
      let citiesTemp
      await this.$firebase.database().ref('leads').child(this.state).child('cities').on('value', snapshot => {
        const values = snapshot.val()
        if (values) {
          citiesTemp = Object.keys(values).map(i => values[i])
          this.cityList = []
          for (let i = 0; i < citiesTemp.length; i++) {
            this.cityList.push(citiesTemp[i].cityName)
          }
        }
      })
    },

    async putLeadAfilliate (leadKey) {
      await this.$firebase.database().ref('leads').child(this.state).child('cities').child(this.city).child('leads').child(leadKey).update({ affiliateId: window.uid })
    }
  }
}
</script>

<style lang="scss" scoped>
.status-info{
    min-width: 40px;
}
.loading-sm{
  width: 85px;
  height: 27px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 8px;
}
.loading-md{
  width: 120px;
  height: 27px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 8px;
}
.loading-lg{
  width: 180px;
  height: 27px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 8px;
}
.loading-btn{
  width: 138px;
  height: 47px;
  background: gray;
  border-radius: 10px;
}
.modal-header{
  border-bottom: none !important;
  button{
    opacity: 1 !important;
    padding-right: 0 !important;
  }
}
.modal-content{
  background-color: transparent !important;
  .row{
    padding: 0 !important;
  }
}
.player {
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;
    height: 450px;
    width: 800px;
    transform: translate(18px, 0);
}
</style>
