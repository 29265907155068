<template>
  <div>
    <button class="btn-action" style="color: var(--featured)" @click="showModal = true"><i class="fas fa-play"></i></button>

    <div class="modal fade" :class="{show: showModal}" :style="{display: showModal ? 'block' : 'none'}">
      <div class="modal-dialog modal-dialog-centered" role="dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Ligar Afiliado</h4>
            <button type="button" class="close" @click="closeModal()" data-bs-dismiss="modal" aria-label="Close">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <h5>Deseja ligar o afiliado de nome: {{affiliate.name}} ?</h5>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeModal()" :disabled="loading" data-bs-dismiss="modal">Fechar</button>
              <button class="btn btn-primary" @click="activeAffiliate()">
                <template v-if="loading" style="transition: all .3s !important">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </template>
                <template v-else>
                  <i class="fas fa-times"></i>Ligar Afiliado
                </template>
              </button>

            </div>
        </div>
      </div>
      <div v-if="status.info" class="alert text-center" :class="status.color" role="alert">
        {{status.info}}
      </div>
      <div class="modal-backdrop fade" :class="{show: showModal}" @click="closeModal()" :style="{display: showModal ? 'block' : 'none'}"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  props: ['affiliate'],
  data: () => ({
    showModal: false,
    loading: false,
    contacts: [],
    status: {
      info: '',
      color: ''
    }
  }),

  created () {
    Vue.set(this.affiliate, 'oldCnpj', this.affiliate.cnpj)
  },

  methods: {
    closeModal () {
      this.showModal = false
    },
    activeAffiliate () {
      this.loading = true

      this.$firebase.database().ref('affiliates').child(this.affiliate.uId).update({ actived: true }, err => {
        if (err) {
          console.log(err)
        } else {
          this.status = {
            info: 'Afiliado Desligado',
            color: 'alert-success'
          }
        }
      })

      this.$root.$emit('Refresh::affiliates')

      setTimeout(() => {
        this.loading = false
      }, 2000)

      setTimeout(() => {
        this.status = {
          info: '',
          color: ''
        }
      }, 3000)

      setTimeout(() => {
        this.showModal = false
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content{
  .modal-header, .modal-footer{
    border: none !important;
  }

  h4, h5{
    font-weight: 700;
  }
  h5{
    font-size: 15px;
  }
  .form-group{
    margin: 7px 0;
  }
}
</style>
