<template>
  <div class="container-fluid">
    <div class="row">
      <layout-navigation/>
      <div class="col-lg-12 col-content">
       <div class="content">
          <h5 class="router-text">Clientes</h5>
          <div class="container-fluid mt-3">
            <div class="row info-container">
              <div>
                <h4>
                  Adicione aqui seus novos clientes
                </h4>
                <p class="mt-3">
                  Não perca mais tempo com os processos chatos, os nossos robôs<br>fazem isso por você e pela a sua empresa!
                </p>
                <div class="container">
                  <div class="row">
                    <new-user/>
                    <button class="btn btn-secondary btn-sm ml-2" @click="$router.push({ name: 'home' })">
                        Ir Para Home
                    </button>
                  </div>
                </div>
              </div>
              <!--<new-customer style="margin-top: -145px;"/>-->
            </div>
          </div>
          <div class="container-fluid mt-3">
            <div class="row search-container">
              <div class="col-8">
                <input
                  type="name"
                  v-model="searchValue"
                  class="form-control"
                  placeholder="Digite quem deseja buscar..."
                  required
                >
              </div>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">Razão Social</th>
                <th scope="col">CNPJ</th>
                <th scope="col">Ações</th>
                <th scope="col">Plano</th>
                <th scope="col">Assinatura</th>
                <th scope="col">Emissões</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr
                :key="i"
                v-for="(user, i) in filteredUsers"
              >
                <td>
                  <template v-if="user.actived">
                    <div class="column">
                      <div
                        class="status status-success text-center"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Ativo">
                      </div>
                      <p class="status-info">Ativo</p>
                    </div>
                  </template>
                  <template v-else>
                    <div class="column">
                      <div
                        class="status status-danger text-center"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Desligado">
                      </div>
                      <p class="status-info">Desligado</p>
                    </div>
                  </template>
                </td>
                <template v-if="user && user.name.length > 13">
                  <td>{{user.name.substring(0, 15)}}...</td>
                </template>
                <template v-else>
                  <td>{{user.name}}</td>
                </template>
                <td>{{user.cnpj}}</td>
                <td>
                  <div class="row" style="padding: 0 12px !important;">
                    <active-user :user="user"/>
                    <disable-user :user="user"/>
                    <edit-user :userData="user"/>
                  </div>
                </td>
                <td>{{getNamePlan(user.currentPlanId)}}</td>
                <td>{{getPaymentStatus(user.currentSubscriptionId)}}</td>
                <td>{{user.emissions}}</td>
                <td style="width: 135px;"><a class="btn btn-sm btn-primary" @click="accessAccount(user.email, user.password)">Acessar</a></td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
              :key="i"
              v-for="(installment, i) in 15"
              >
                <td><div class="loading-sm"></div></td>
                <td><div class="loading-md"></div></td>
                <td><div class="loading-sm"></div></td>
                <td><div class="loading-md"></div></td>
                <td><div class="loading-md"></div></td>
                <td><div class="loading-sm"></div></td>
                <td><div class="loading-sm"></div></td>
              </tr>
            </tbody>
          </table>
       </div>
      </div>
    </div>
    <div class="modal fade" :class="{show: showTutorial}" :style="{display: showTutorial ? 'block' : 'none'}">
      <div class=" modal-dialog modal-dialog-centered modal-lg" role="dialog" style="transform: translate(0, -40px); z-index: 9999;">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <button type="button" class="close" @click="showTutorial = false; pauseVideo()" data-bs-dismiss="modal" aria-label="Close">
              <i class="fas fa-times" style="color: white;"></i>
            </button>
          </div>
          <div class="row justify-content-center player">
            <youtube player-width="800" player-height="450" :video-id="'HzU5zVX17no'" ref="youtube"></youtube>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade" :class="{show: showTutorial}" @click="showTutorial = false; pauseVideo()" :style="{display: showTutorial ? 'block' : 'none'}"></div>
    </div>
  </div>
</template>

<script>
import LayoutNavigation from '../../components/layout/LayoutNavigation'
import NewUser from './NewUser'
import EditUser from './EditUser'
import DisableUser from './DisableUser'
import ActiveUser from './ActiveUser'

export default {
  name: 'Home',
  components: {
    LayoutNavigation,
    NewUser,
    EditUser,
    DisableUser,
    ActiveUser
  },

  data: () => ({
    users: [],
    searchValue: '',
    loading: true,
    showTutorial: false,
    plans: [],
    subscriptions: []
  }),

  async created () {
    this.$root.$on('Refresh::users', this.getData)
    await this.getPlans()
    await this.getData()
  },

  computed: {
    filteredUsers () {
      let tempCustomers = this.users

      if (this.searchValue !== '' && this.searchValue) {
        tempCustomers = tempCustomers.filter((item) => {
          if (this.searchValue.toUpperCase() === 'ATIVO') {
            return item.actived.toString().toUpperCase().includes('TRUE')
          } else if (this.searchValue.toUpperCase() === 'DESATIVADO') {
            return item.actived.toString().toUpperCase().includes('FALSE')
          } else {
            if (item.uId) {
              return item.uId.toUpperCase().includes(this.searchValue.toUpperCase()) + item.name
                .toUpperCase()
                .includes(this.searchValue.toUpperCase()) + item.cnpj
                .toUpperCase()
                .includes(this.searchValue.toUpperCase()) + item.email
                .toUpperCase()
                .includes(this.searchValue.toUpperCase()) + this.getNamePlan(item.currentPlanId)
                .toUpperCase()
                .includes(this.searchValue.toUpperCase()) + this.getPaymentStatus(item.currentSubscriptionId)
                .toUpperCase()
                .includes(this.searchValue.toUpperCase())
            } else if (item.uid) {
              return item.uid.toUpperCase().includes(this.searchValue.toUpperCase() + item.name
                .toUpperCase()
                .includes(this.searchValue.toUpperCase()) + item.cnpj
                .toUpperCase()
                .includes(this.searchValue.toUpperCase()) + item.email
                .toUpperCase()
                .includes(this.searchValue.toUpperCase()) + this.getNamePlan(item.currentPlanId)
                .toUpperCase()
                .includes(this.searchValue.toUpperCase()) + this.getPaymentStatus(item.currentSubscriptionId)
                .toUpperCase()
                .includes(this.searchValue.toUpperCase()))
            }
          }
        })
      }

      tempCustomers = tempCustomers.sort((a, b) => {
        let fb
        let fa
        if (a.createdAt) {
          fa = this.toTimestamp(a.createdAt)
        } else {
          fa = 0
        }
        if (b.createdAt) {
          fb = this.toTimestamp(b.createdAt)
        } else {
          fb = 0
        }
        if (fa > fb) {
          return -1
        }
        if (fa < fb) {
          return 1
        }
        return 0
      })

      tempCustomers = tempCustomers.sort((a, b) => {
        if (!b.createdAt) {
          return -1
        }
        return 0
      })

      return tempCustomers
    }
  },

  methods: {
    toTimestamp (strDate) {
      var datum = Date.parse(strDate)
      return datum / 100
    },
    accessAccount (email, password) {
      window.open(`https://emissaosimples.com.br/login/${this.CryptoJS.AES.encrypt(email, 'Secret Passphrase').toString().replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l')}/${this.CryptoJS.AES.encrypt(password, 'Secret Passphrase').toString().replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l')}`, '_blank')
    },
    getNamePlan (currentPlanId) {
      for (let i = 0; i < this.plans.length; i++) {
        if (this.plans[i].pagarmeId === currentPlanId) {
          return this.plans[i].name
        }
      }
      return 'Não Assinante'
    },
    getPaymentStatus (currentSubscriptionId) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        if (this.subscriptions[i].id === currentSubscriptionId) {
          if (this.subscriptions[i].status === 'paid') {
            return 'Pago'
          } else if (this.subscriptions[i].status === 'canceled') {
            return 'Cancelado'
          } else {
            return 'Inadimplente'
          }
        }
      }
      return 'Não Assinante'
    },
    async getPlans () {
      await this.$firebase.database().ref('plans').once('value', snapshot => {
        this.plans = snapshot.val()
      })
    },
    async getData () {
      await this.$firebase.database().ref('users').once('value', snapshot => {
        const values = snapshot.val()
        if (values) {
          this.users = Object.keys(values).map(i => values[i])
        }
        this.$firebase.database().ref('apiKeys').child('pagarmeApiKey').once('value', snapshot => {
          this.$pagarme.client.connect({ api_key: snapshot.val() }).then(client => client.subscriptions.all({ count: 1000 })).then(subscription => {
            this.subscriptions = subscription
            this.loadingPage = false
          })
        })
        setTimeout(() => {
          this.loading = false
        }, 1000)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.status-info{
    min-width: 85px;
}
.loading-sm{
  width: 85px;
  height: 27px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 8px;
}
.loading-md{
  width: 120px;
  height: 27px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 8px;
}
.loading-lg{
  width: 180px;
  height: 27px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 8px;
}
.loading-btn{
  width: 138px;
  height: 47px;
  background: gray;
  border-radius: 10px;
}
.modal-header{
  border-bottom: none !important;
  button{
    opacity: 1 !important;
    padding-right: 0 !important;
  }
}
.modal-content{
  background-color: transparent !important;
  .row{
    padding: 0 !important;
  }
}
.player {
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;
    height: 450px;
    width: 800px;
    transform: translate(18px, 0);
}
</style>
