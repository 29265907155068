<template>
  <div>
    <button class="btn-action" @click="showModal = true; initialChanges ()"><i class="fas fa-info-circle"></i></button>

    <div class="modal fade" :class="{show: showModal}" :style="{display: showModal ? 'block' : 'none'}">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Dados da Lead</h4>
            <button type="button" class="close" @click="closeModal()" data-bs-dismiss="modal" aria-label="Close">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <form @submit.prevent="editLead()" v-if="lead.status">
            <div class="modal-body">
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Status da Lead</p>
                <select class="form-control" @change="onChangeStatus()" v-model="lead.status.info" required>
                  <option value="Ligar">Ligar</option>
                  <option value="Retornar">Retornar</option>
                  <option value="Testando">Testando</option>
                  <option value="Apresentado">Apresentado</option>
                  <option value="Não Quer">Não Quer</option>
                  <option value="Assinante">Assinante</option>
                </select>
              </div>

              <div class="mt-2" v-if="lead.status.info === 'Retornar'">
                <p class="mb-0 font-weight-bold">Data de Retorno</p>
                <v-date-picker v-model="lead.dtReturn" mode="date" @input="editLead" color="green" required is24hr>
                  <template v-slot="{ inputValue, inputEvents}">
                  <div class="flex flex-col sm:flex-row justify-start items-center">
                      <div class="relative flex-grow">
                        <svg
                          class="text-gray-600 position-absolute"
                          style="margin-left: 12px"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.7"
                          viewBox="0 0 24 24"
                          stroke="#065247"
                          width="1.5em" height="2.8em"
                        >
                          <path
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                          ></path>
                        </svg>
                        <input
                          class="flex-grow pl-5 pr-0 w-100"
                          style="border-radius: 10px; cursor: pointer;"
                          placeholder="Data de Retorno"
                          :value="inputValue"
                          v-on="inputEvents"
                          required
                        />
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>

              <div class="form-group">
                <p class="mb-0 font-weight-bold">Nome</p>
                <input
                  type="name"
                  class="form-control"
                  placeholder="Nome"
                  v-model="lead.name"
                  required
                  disabled
                  style="text-transform:uppercase"
                  @change="editLead()"
                >
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Celular</p>
                <input
                  type="phone"
                  v-model="lead.phone"
                  class="form-control"
                  placeholder="Celular"
                  v-mask="lead.phone.length >= 15 ? '(##) #####-####' : '(##) ####-####'"
                  minlength="14"
                  required
                  @input="editLead"
                >
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Whatsapp</p>
                <input
                  type="phone"
                  v-model="lead.whatsapp"
                  class="form-control"
                  placeholder="Whatsapp"
                  v-mask="lead.whatsapp.length >= 15 ? '(##) #####-####' : '(##) ####-####'"
                  minlength="14"
                  @input="editLead"
                >
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">E-mail</p>
                <input
                  type="text"
                  v-model="lead.email"
                  class="form-control"
                  placeholder="E-mail"
                  @change="editLead()"
                >
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <p class="mb-0 font-weight-bold">Nome do Funcionário</p>
                    <input
                      type="name"
                      class="form-control"
                      placeholder="Nome do Funcionário"
                      v-model="lead.employeeName"
                      @change="editLead()"
                    >
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <p class="mb-0 font-weight-bold">Nome do Dono</p>
                    <input
                      type="name"
                      class="form-control"
                      placeholder="Nome do Dono"
                      v-model="lead.nameOwner"
                      @change="editLead()"
                    >
                  </div>
                </div>
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Observação</p>
                <textarea
                  type="text"
                  v-model="lead.observation"
                  class="form-control"
                  placeholder="Observação"
                  @change="editLead()"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <!-- <button type="button" class="btn btn-secondary" @click="closeModal()" data-bs-dismiss="modal">Close</button> -->
              <!-- <p class="status" :style="[status ? { 'background-color' : '#fff1c6' } : { 'background-color' : '#fff' }]">{{status}}</p> -->
              <button class="btn btn-primary" :disabled="loading">
                <template v-if="loading" style="transition: all .3s !important">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </template>
                <template v-else>
                  Editar Lead
                </template>
              </button>

            </div>
          </form>
        </div>
      </div>
      <div v-if="status.info" class="alert text-center" :class="status.color" role="alert">
        {{status.info}}
      </div>
      <div class="modal-backdrop fade" :class="{show: showModal}" @click="closeModal()" :style="{display: showModal ? 'block' : 'none'}"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['leadData', 'state', 'city'],
  data: () => ({
    showModal: false,
    loading: false,
    oldCnpj: '',
    hasCpf: false,
    status: {
      info: '',
      color: ''
    },
    lead: {},
    masks: {
      input: 'YYYY-MM-DD'
    }
  }),

  mounted () {
    this.initialChanges()
  },

  methods: {
    initialChanges () {
      if (!this.leadData.whatsapp) {
        this.leadData.whatsapp = ''
      }
      if (!this.leadData.email) {
        this.leadData.email = ''
      }
      if (!this.leadData.observation) {
        this.lead.observation = ''
      }
      this.lead = this.leadData
    },
    onChangeStatus () {
      if (this.lead.status.info === 'Ligar') {
        this.lead.status.color = 'status-pending'
      } else if (this.lead.status.info === 'Retornar') {
        this.lead.status.color = 'status-danger'
        this.status = {
          info: 'Informar data de retorno',
          color: 'alert-danger'
        }
        setTimeout(() => {
          this.status = {
            info: '',
            color: ''
          }
        }, 3000)
        return null
      } else if (this.lead.status.info === 'Testando') {
        this.lead.status.color = 'status-warning'
      } else if (this.lead.status.info === 'Não Quer') {
        this.lead.status.color = 'status-danger-dark'
      } else if (this.lead.status.info === 'Assinante') {
        this.lead.status.color = 'status-success'
      } else if (this.lead.status.info === 'Apresentado') {
        this.lead.status.color = 'status-sent'
      }
      this.lead.dtReturn = ''
      this.editLead()
    },
    closeModal () {
      this.showModal = false
      this.$root.$emit('Refresh::affiliates')
    },
    async editLead () {
      if (this.lead.status.info === 'Retornar' && !this.lead.dtReturn) {
        this.status = {
          info: 'Informar data de retorno',
          color: 'alert-danger'
        }
        setTimeout(() => {
          this.status = {
            info: '',
            color: ''
          }
        }, 3000)

        return null
      }

      this.loading = true

      await this.$firebase.database().ref('leads').child(this.state).child('cities').child(this.city).child('leads').child(this.lead.leadKey).update(this.lead)

      setTimeout(() => {
        this.loading = false
      }, 500)

      // this.$firebase.database().ref('leads').child(this.state).child('cities').child(this.city).child('leads').child(this.lead.leadKey).update(this.lead, err => {
      //   if (err) {
      //     console.log(err)
      //   } else {
      //     this.status = {
      //       info: 'Lead Editada',
      //       color: 'alert-success'
      //     }
      //   }
      // })

      // this.$root.$emit('Refresh::affiliates')

      // setTimeout(() => {
      //   this.loading = false
      // }, 2000)

      // setTimeout(() => {
      //   this.status = {
      //     info: '',
      //     color: ''
      //   }
      // }, 3000)

      // setTimeout(() => {
      //   this.showModal = false
      // }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content{
  .modal-body{
    padding-top: 0;
    height: 60vh;
  }

  .modal-header, .modal-footer{
    border: none !important;
  }

  .custom-checkbox{
    margin: 10px 0 0 2px;
  }

  .custom-control-label::before{
    height: 1.2rem !important;
    width: 1.2rem !important;
    top: .15rem;
    left: -1.60rem;
    box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.15) !important;
    border-color: white !important;
    border-radius: 5px;
  }
  .custom-control-label::after{
    border-color: var(--featured) !important;
  }
  .custom-control-input:checked~.custom-control-label::before {
    box-shadow: none !important;
    color: white !important;
    background-color: var(--featured) !important;
    border-color: var(--featured) !important;
    &:focus {
        border: none !important;
    }
  }
  h4, h5{
    font-weight: 700;
  }
  .form-group{
    margin: 7px 0;
  }
  .btn-remove{
    color: var(--red-light);
    padding: 0;
    border: none;
    background-color: white;
    margin-left: 3px;
    i {
      margin-right: 5px;
    }
  }
}
</style>
