<template>
  <div>
    <button class="btn btn-sm btn-primary" @click="showModal = true">
      <i class="fas fa-plus"></i>Adicionar Cliente
    </button>

    <div class="modal fade" :class="{show: showModal}" :style="{display: showModal ? 'block' : 'none'}">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Adicionar Cliente</h4>
            <button type="button" class="close" @click="closeModal()" data-bs-dismiss="modal" aria-label="Close">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <form @submit.prevent="createUser()">
            <div class="modal-body">
              <h5 class="mb-2">Informações da Empresa</h5>
              <div class="form-group">
              <p class="mb-0 font-weight-bold">CNPJ</p>
                <div class="input-group">
                  <input
                    type="cnpj"
                    v-model="user.cnpj"
                    class="form-control"
                    placeholder="CNPJ da Empresa"
                    v-mask="'##.###.###/####-##'"
                    required
                  >
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroupPrepend2" @click="autoInfoRequest()">
                      <template v-if="infoLoading">
                        <i class="fas fa-circle-notch fa-spin"></i>
                      </template>
                      <template v-else>
                        <i class="fa fa-search"></i>
                      </template>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Razão Social</p>
                <input
                  type="name"
                  v-model="user.name"
                  class="form-control"
                  placeholder="Razão Social"
                  required
                  style="text-transform:uppercase"
                >
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Nome Fantasia</p>
                <input
                  type="name"
                  v-model="user.fantasia"
                  class="form-control"
                  placeholder="Nome Fantasia"
                  required
                >
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Celular</p>
                <input
                  type="phone"
                  v-model="user.phone"
                  class="form-control"
                  placeholder="Celular do Responsável"
                  v-mask="user.phone.length >= 15 ? '(##) #####-####' : '(##) ####-####'"
                  minlength="14"
                  required
                >
              </div>
              <div class="container-flex">
                <div class="row">
                  <div class="form-group col-4">
                    <p class="mb-0 font-weight-bold">Estado</p>
                    <input
                      type="uf"
                      v-model="user.uf"
                      class="form-control"
                      placeholder="UF"
                      v-mask="'AA'"
                      style="text-transform:uppercase"
                      required
                    >
                  </div>
                  <div class="form-group col-8 pl-0">
                    <p class="mb-0 font-weight-bold">Município</p>
                    <input
                      autocomplete="false"
                      type=""
                      v-model="user.city"
                      class="form-control"
                      placeholder="Município"
                      required
                    >
                  </div>
                </div>
              </div>
              <div class="container-flex">
                <div class="row">
                  <div class="form-group col-10">
                    <p class="mb-0 font-weight-bold">Rua</p>
                    <input
                      type="uf"
                      v-model="user.street"
                      class="form-control"
                      placeholder="Rua"
                      required
                    >
                  </div>
                  <div class="form-group col-2 pl-0">
                    <p class="mb-0 font-weight-bold">N°</p>
                    <input
                      autocomplete="false"
                      type=""
                      v-model="user.streetNumber"
                      class="form-control"
                      placeholder="N°"
                      required
                    >
                  </div>
                </div>
              </div>

              <div class="container-flex">
                <div class="row">
                  <div class="form-group col-8">
                    <p class="mb-0 font-weight-bold">Bairro</p>
                    <input
                      autocomplete="false"
                      type=""
                      v-model="user.neighborhood"
                      class="form-control"
                      placeholder="Bairro"
                      required
                    >
                  </div>
                  <div class="form-group col-4 pl-0">
                    <p class="mb-0 font-weight-bold">CEP</p>
                    <input
                      autocomplete="false"
                      type=""
                      v-model="user.zipcode"
                      class="form-control"
                      placeholder="CEP"
                      v-mask="'#####-###'"
                      required
                    >
                  </div>
                </div>
              </div>

              <h5 class="mt-3 mb-3">Login do Cliente</h5>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">E-mail de Acesso</p>
                <input
                  type="email"
                  v-model="user.email"
                  class="form-control"
                  placeholder="E-mail de Login"
                  required
                >
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Senha Provisória</p>
                <input
                  type="password"
                  v-model="user.password"
                  class="form-control"
                  placeholder="Defina sua Senha"
                  minlength="8"
                  required
                >
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-primary" :disabled="loading">
                <template v-if="loading" style="transition: all .3s !important">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </template>
                <template v-else>
                  <i class="fas fa-plus"></i>Adicionar Cliente
                </template>
              </button>

            </div>
          </form>
        </div>
      </div>
      <div v-if="status.info" class="alert text-center" :class="status.color" role="alert">
        {{status.info}}
      </div>
      <div class="modal-backdrop fade" :class="{show: showModal}" @click="closeModal()" :style="{display: showModal ? 'block' : 'none'}"></div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    showModal: false,
    loading: false,
    infoLoading: false,
    status: {
      info: '',
      color: ''
    },
    user: {
      actived: false,
      email: '',
      password: '',
      cnpj: '',
      currentSubscriptionId: '',
      currentPlanId: '',
      lastUploadEmissions: '',
      lastBoletoUrl: '',
      totalGuides: 0,
      emissions: 2000,
      name: '',
      phone: '',
      uf: '',
      city: '',
      zipcode: '',
      street: '',
      streetNumber: '',
      neighborhood: '',
      fantasia: '',
      emailToSend: '',
      passwordToSend: '',
      uId: '',
      pagarmeId: '',
      createdAt: '',
      affiliateId: window.uid,
      free: true
    }
  }),

  methods: {
    closeModal () {
      this.$root.$emit('Refresh::users')
      this.showModal = false
    },
    verifyMaskCnpj () {
      this.user.cnpj = this.user.cnpj.substring(0, 3) + '.' + this.user.cnpj.substring(3)
    },
    async autoInfoRequest () {
      this.infoLoading = true
      const headers = {
        'Access-Control-Allow-Origin': '*',
        Authorization: process.env.VUE_APP_RECEITA_WS_TOKEN
      }
      const url = `https://www.receitaws.com.br/v1/cnpj/${this.user.cnpj.replace(/[^a-zA-Z0-9]/g, '')}`
      if (this.user.cnpj.length > 16) {
        // await this.$http.get(`https://thingproxy.freeboard.io/fetch/${url}`, headers).then(res => {
        // await this.$http.get(url, headers).then(res => {
        await this.$http.get(`https://emissaosimples.herokuapp.com/${url}`, headers).then(res => {
        // await this.$http.get(`http://www.whateverorigin.org/get?url=${url}`, headers).then(res => {
          if (res.data.nome.length) {
            this.user.name = res.data.nome
            this.user.uf = res.data.uf
            this.user.city = res.data.municipio
            this.user.fantasia = res.data.fantasia
            this.user.phone = res.data.telefone.substring(0, 15)
            this.user.zipcode = res.data.cep
            this.user.street = res.data.logradouro
            this.user.streetNumber = res.data.numero
            this.user.neighborhood = res.data.bairro
          } else {
            this.user.name = ''
            this.user.uf = ''
            this.user.city = ''
            this.user.fantasia = ''
            this.user.phone = ''
            this.user.zipcode = ''
            this.user.street = ''
            this.user.streetNumber = ''
            this.user.neighborhood = ''
            this.status = {
              info: 'CNPJ não encontrado',
              color: 'alert-danger'
            }
            setTimeout(() => {
              this.infoLoading = false
            }, 2000)

            setTimeout(() => {
              this.status = {
                info: '',
                color: ''
              }
            }, 3000)
            return null
          }
        }).catch(err => {
          console.log(err)
          this.user.name = ''
          this.user.uf = ''
          this.user.city = ''
          this.user.fantasia = ''
          this.user.phone = ''
          this.user.zipcode = ''
          this.user.street = ''
          this.user.streetNumber = ''
          this.user.neighborhood = ''
          this.status = {
            info: 'CNPJ não encontrado',
            color: 'alert-danger'
          }
          setTimeout(() => {
            this.infoLoading = false
          }, 2000)

          setTimeout(() => {
            this.status = {
              info: '',
              color: ''
            }
          }, 3000)
          return err
        })
      }
      this.infoLoading = false
    },
    async createUser () {
      if (this.user.cnpj.length < 16) {
        this.status = {
          info: 'CNPJ Inválido',
          color: 'alert-warning'
        }
        setTimeout(() => {
          this.status = {
            info: '',
            color: ''
          }
        }, 3000)
        return null
      }
      this.loading = true

      this.user.createdAt = Date()
      await this.$firebase.database().ref('affiliates').child(window.uid).child('percentage').on('value', snapshot => {
        this.user.affiliatePercentage = parseInt(snapshot.val())
      })

      try {
        await this.$authWorker.auth().createUserWithEmailAndPassword(this.user.email, this.user.password).then(res => {
          this.user.uId = res.user.uid
          this.$firebase.database().ref('users').child(res.user.uid).set(this.user).then(() => {
            this.$authWorker.auth().signOut()
          })
        })
      } catch (err) {
        console.log(err)
        if (err.message === 'The email address is already in use by another account.') {
          this.status = {
            info: 'Este e-mail já está sendo usado em outra conta!',
            color: 'alert-danger'
          }
        }

        setTimeout(() => {
          this.loading = false
        }, 2000)

        setTimeout(() => {
          this.status = {
            info: '',
            color: ''
          }
        }, 3000)
        this.loading = false
        return null
      }
      setTimeout(() => {
        this.loading = false
        this.closeModal()
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content{
  width: 580px !important;

  .modal-body{
    padding-top: 0;
    height: 70vh;
  }

  .modal-header, .modal-footer{
    border: none !important;
  }

  .custom-checkbox{
    margin: 10px 0 0 2px;
  }

  .custom-control-label::before{
    height: 1.2rem !important;
    width: 1.2rem !important;
    top: .15rem;
    left: -1.60rem;
    box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.15) !important;
    border-color: white !important;
    border-radius: 5px;
  }
  .custom-control-label::after{
    border-color: var(--featured) !important;
  }
  .custom-control-input:checked~.custom-control-label::before {
    box-shadow: none !important;
    color: white !important;
    background-color: var(--featured) !important;
    border-color: var(--featured) !important;
    &:focus {
        border: none !important;
    }
  }
  h4, h5{
    font-weight: 700;
  }
  .form-group{
    margin: 7px 0;
  }
  .btn-remove{
    color: var(--red-light);
    padding: 0;
    border: none;
    background-color: white;
    margin-left: 3px;
    i {
      margin-right: 5px;
    }
  }
}
.input-group-text{
  position: absolute;
  background-color: var(--featured) !important;
  color: white;
  right: 2px;
  bottom: 3px;
  top: 3px;
  border: none;
  cursor: pointer;
  border-radius: 10px !important;
  z-index: 999;
}
</style>
