<template>
  <div>
    <button class="btn-action" style="color: var(--dark); padding: 3.5px 5px; font-size: 12.8px;" @click="showModal = true, initialChanges()" :disabled="affiliateData.loading"><i class="fas fa-user-edit"></i></button>

    <div class="modal fade" :class="{show: showModal}" :style="{display: showModal ? 'block' : 'none'}">
      <div class="modal-dialog modal-dialog-scrollable" role="dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Editar Afiliado</h4>
            <button type="button" class="close" @click="closeModal()" data-bs-dismiss="modal" aria-label="Close">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <form @submit.prevent="EditAffiliate()">
            <div class="modal-body">
              <div class="form-group">
                <p class="mb-0 font-weight-bold">CNPJ</p>
                <input
                  type="cnpj"
                  class="form-control"
                  placeholder="CNPJ"
                  v-model="affiliate.cnpj"
                  v-mask="'##.###.###/####-##'"
                  required
                >
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Nome</p>
                <input
                  type="name"
                  class="form-control"
                  placeholder="Nome"
                  v-model="affiliate.name"
                  required
                  style="text-transform:uppercase"
                >
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">CPF</p>
                <input
                  type="cnpj"
                  class="form-control"
                  placeholder="CPF"
                  v-model="affiliate.cpf"
                  v-mask="'###.###.###-##'"
                  required
                >
              </div>
              <div class="row">
                <div class="form-group col-4">
                  <p class="mb-0 font-weight-bold">Estado</p>
                  <input
                    type="uf"
                    v-model="affiliate.uf"
                    class="form-control"
                    placeholder="UF"
                    v-mask="'AA'"
                    style="text-transform:uppercase"
                    required
                  >
                </div>
                <div class="form-group col-8 pl-0">
                  <p class="mb-0 font-weight-bold">Cidade</p>
                  <input
                    autocomplete="false"
                    type=""
                    v-model="affiliate.city"
                    class="form-control"
                    placeholder="Município"
                    required
                  >
                </div>
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Celular</p>
                <input
                  type="phone"
                  v-model="affiliate.phone"
                  class="form-control"
                  placeholder="Celular do Responsável"
                  v-mask="affiliate.phone.length >= 15 ? '(##) #####-####' : '(##) ####-####'"
                  minlength="14"
                  required
                >
              </div>
              <div class="row">
                <div class="col-8">
                  <div class="form-group">
                    <p class="mb-0 font-weight-bold">Indicação</p>
                    <input
                      type="name"
                      class="form-control"
                      placeholder="Indicação"
                      v-model="affiliate.indicated"
                      required
                    >
                  </div>
                </div>
                <div class="col-4 pl-0">
                  <div class="form-group">
                    <p class="mb-0 font-weight-bold">Comissão</p>
                    <select class="form-control" v-model="affiliate.percentage" required>
                      <option value="10">10%</option>
                      <option value="20">20%</option>
                      <option value="30">30%</option>
                      <option value="40">40%</option>
                      <option value="50">50%</option>
                    </select>
                  </div>
                </div>
              </div>
              <h5 class="mt-3 mb-3">Login do Afiliado</h5>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">E-mail de acesso</p>
                <input
                  type="email"
                  v-model="affiliate.email"
                  class="form-control"
                  placeholder="E-mail de Login"
                  autocomplete="off"
                  onfocus="this.removeAttribute('readonly');" readonly
                  required
                  disabled
                >
              </div>
              <div class="form-group">
                <p class="mb-0 font-weight-bold">Senha</p>
                <input
                  type="text"
                  v-model="affiliate.password"
                  class="form-control"
                  placeholder="Defina sua Senha"
                  minlength="8"
                  autocomplete="off"
                  onfocus="this.removeAttribute('readonly');" readonly
                  required
                  disabled
                >
              </div>
            </div>
            <div class="modal-footer">
              <!-- <button type="button" class="btn btn-secondary" @click="closeModal()" data-bs-dismiss="modal">Close</button> -->
              <!-- <p class="status" :style="[status ? { 'background-color' : '#fff1c6' } : { 'background-color' : '#fff' }]">{{status}}</p> -->
              <button class="btn btn-primary" :disabled="loading">
                <template v-if="loading" style="transition: all .3s !important">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </template>
                <template v-else>
                  Editar Afiliado
                </template>
              </button>

            </div>
          </form>
        </div>
      </div>
      <div v-if="status.info" class="alert text-center" :class="status.color" role="alert">
        {{status.info}}
      </div>
      <div class="modal-backdrop fade" :class="{show: showModal}" @click="closeModal()" :style="{display: showModal ? 'block' : 'none'}"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['affiliateData'],
  data: () => ({
    showModal: false,
    loading: false,
    oldCnpj: '',
    hasCpf: false,
    status: {
      info: '',
      color: ''
    },
    affiliate: {
      affiliateId: '',
      actived: true,
      cnpj: '',
      cpf: '',
      name: '',
      phone: '',
      uf: '',
      city: '',
      percentage: '',
      indicated: '',
      email: '',
      password: ''
    },
    oldEmail: '',
    oldPassword: ''
  }),

  methods: {
    initialChanges () {
      this.oldCnpj = this.affiliateData.cnpj
      if (this.affiliateData.cpf.length > 0) {
        this.hasCpf = true
      }
      this.affiliate.affiliateId = this.affiliateData.affiliateId
      this.affiliate.cnpj = this.affiliateData.cnpj
      this.affiliate.cpf = this.affiliateData.cpf
      this.affiliate.name = this.affiliateData.name
      this.affiliate.uf = this.affiliateData.uf
      this.affiliate.city = this.affiliateData.city
      this.affiliate.phone = this.affiliateData.phone
      this.affiliate.indicated = this.affiliateData.indicated
      this.affiliate.percentage = this.affiliateData.percentage
      this.affiliate.password = this.affiliateData.password
      this.affiliate.email = this.affiliateData.email
      // this.oldPassword = this.affiliateData.password
      // this.oldEmail = this.affiliateData.email
    },
    closeModal () {
      this.showModal = false
      this.$root.$emit('Refresh::affiliates')
    },
    async EditAffiliate () {
      if (this.affiliate.cnpj.length < 18) {
        this.status = {
          info: 'CNPJ Inválido',
          color: 'alert-warning'
        }
        setTimeout(() => {
          this.status = {
            info: '',
            color: ''
          }
        }, 3000)
        return null
      }
      if (this.affiliate.cpf && this.affiliate.cpf.length < 11) {
        this.status = {
          info: 'CPF Inválido',
          color: 'alert-warning'
        }
        setTimeout(() => {
          this.status = {
            info: '',
            color: ''
          }
        }, 3000)
        return null
      }

      this.loading = true
      this.affiliates = []

      // if (this.oldEmail !== this.affiliate.email || this.oldPassword !== this.affiliate.password) {
      //   await this.$firebase.auth()
      //     .signInWithEmailAndPassword(this.oldEmail, this.oldPassword)
      //     .then(userCredential => {
      //       userCredential.user.updateEmail(this.affiliate.email)
      //       userCredential.user.updatePassword(this.affiliate.password)
      //     })
      // }
      this.$firebase.database().ref('affiliates').child(this.affiliate.affiliateId).update(this.affiliate, err => {
        if (err) {
          console.log(err)
        } else {
          this.status = {
            info: 'Afiliado Editado',
            color: 'alert-success'
          }
        }
      })

      this.$root.$emit('Refresh::affiliates')

      setTimeout(() => {
        this.loading = false
      }, 2000)

      setTimeout(() => {
        this.status = {
          info: '',
          color: ''
        }
      }, 3000)

      setTimeout(() => {
        this.showModal = false
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content{
  .modal-body{
    padding-top: 0;
    height: 70vh;
  }

  .modal-header, .modal-footer{
    border: none !important;
  }

  .custom-checkbox{
    margin: 10px 0 0 2px;
  }

  .custom-control-label::before{
    height: 1.2rem !important;
    width: 1.2rem !important;
    top: .15rem;
    left: -1.60rem;
    box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.15) !important;
    border-color: white !important;
    border-radius: 5px;
  }
  .custom-control-label::after{
    border-color: var(--featured) !important;
  }
  .custom-control-input:checked~.custom-control-label::before {
    box-shadow: none !important;
    color: white !important;
    background-color: var(--featured) !important;
    border-color: var(--featured) !important;
    &:focus {
        border: none !important;
    }
  }
  h4, h5{
    font-weight: 700;
  }
  .form-group{
    margin: 7px 0;
  }
  .btn-remove{
    color: var(--red-light);
    padding: 0;
    border: none;
    background-color: white;
    margin-left: 3px;
    i {
      margin-right: 5px;
    }
  }
}
</style>
